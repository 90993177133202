<template>
  <div>
    <v-container>
      <v-tabs>
        <v-tab class="text-sm-left">
          Cotizador
        </v-tab>

        <!--   <v-tab class="text-sm-left" >

          Promesa
        </v-tab> -->

        <v-tab-item>
          <v-container>
            <div class="pb-2">
              <v-row>
                <v-col cols="3">
                  <v-select
                    :items="languagesList"
                    color="#042956"
                    label="Language"
                    v-model="selectedLanguage"
                  ></v-select>
                </v-col>
              </v-row>
            </div>

            <v-stepper v-model="stepNumber">
              <v-stepper-header>
                <v-stepper-step
                  :complete="stepNumber > 1"
                  step="1"
                  color="#042956"
                  editable
                >
                  {{ paso1TextoCabezera }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                  :complete="stepNumber > 2"
                  :editable="stepNumber >= 2"
                  step="2"
                  color="#042956"
                >
                  {{ paso2TextoCabezera }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                  :complete="stepNumber > 3"
                  :editable="stepNumber >= 3"
                  step="3"
                  color="#042956"
                >
                  {{ paso3TextoCabezera }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                  :complete="stepNumber > 4"
                  :editable="stepNumber >= 4"
                  step="4"
                  color="#042956"
                >
                  {{ paso4TextoCabezera }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                  :complete="stepNumber > 5"
                  :editable="stepNumber >= 5"
                  step="5"
                  color="#042956"
                >
                  {{ paso5TextoCabezera }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                  :complete="stepNumber > 6"
                  :editable="stepNumber >= 6"
                  step="6"
                  color="#042956"
                >
                  <div v-if="selectedLanguage == 'Español'">
                    Elegir Broker
                  </div>
                  <div v-else>
                    Select Broker
                  </div>
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <div>
                    <v-text-field
                      :label="nombreProspectoLabel"
                      prepend-icon="mdi-account"
                      color="#042956"
                      v-model="nombreProspecto"
                      clearable
                    ></v-text-field>
                  </div>

                  <btnNextStep
                    v-on:nextStep="nextStep"
                    :disabled="nombreProspecto == ''"
                    :message="msgBtnSig"
                  />
                </v-stepper-content>

                <v-stepper-content step="2">
                  <div class="py-10 px-5">
                    <v-row align="center">
                      <div>
                        <div v-if="selectedLanguage == 'Español'">
                          Selecciona las unidades.
                        </div>
                        <div v-else>
                          Select properties.
                        </div>
                      </div>
                      <div>
                        <div
                          v-if="selectedLanguage == 'Español'"
                          class="px-4 grey--text"
                        >
                          ( Solo se muestran unidades disponibles )
                        </div>
                        <div v-else class="px-4 grey--text">
                          ( Only available units are shown )
                        </div>
                      </div>
                    </v-row>
                  </div>
                  <!--return-object-->
                  <v-autocomplete
                    :items="listaPropiedadesIDs"
                    v-model="listaPropiedadesSeleccionadas"
                    clearable
                    chips
                    color="#042956"
                    prepend-icon="mdi-city"
                    :label="propiedadesLabel"
                    @change="selectedCurrency"
                    :item-text="textSelected"
                    return-object
                    multiple
                    deletable-chips
                  >
                    <template v-slot:item="{ item, attrs, on }">
                      <v-list-item
                        v-on="on"
                        v-bind="attrs"
                        #default="{ active }"
                      >
                        <v-list-item-action>
                          <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-row no-gutters align="center">
                              <span>{{ item.name }}</span>
                              <v-spacer></v-spacer>
                              <v-chip
                                text-color="white"
                                color="primary"
                                small
                                >{{ item.floor.name }}</v-chip
                              >
                            </v-row>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>

                  <div class="d-flex justify-space-between">
                    <btnPreviousStep
                      v-on:previousStep="previousStep"
                      :message="msgBtnPrev"
                    />
                    <btnNextStep
                      v-on:nextStep="nextStep"
                      :disabled="listaPropiedadesSeleccionadas.length <= 0"
                      :message="msgBtnSig"
                    />
                  </div>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <div class="pl-3">
                    <p>
                      <v-icon>
                        mdi-cash
                      </v-icon>
                      {{ elijeMoneda }}
                    </p>

                    <v-radio-group v-model="moneda" row>
                      <v-radio
                        label="USD"
                        value="USD"
                        color="#042956"
                      ></v-radio>

                      <v-radio
                        label="MXN"
                        value="MXN"
                        color="#042956"
                      ></v-radio>
                    </v-radio-group>

                    <div v-show="defMoneda != moneda">
                      <v-text-field
                        :label="tipoCambioLabel"
                        v-model="tipoCambio"
                        prefix="$"
                        type="number"
                        step="0.01"
                        color="#042956"
                      ></v-text-field>
                    </div>
                  </div>

                  <div class="d-flex justify-space-between">
                    <btnPreviousStep
                      v-on:previousStep="previousStep"
                      :message="msgBtnPrev"
                    />
                    <btnNextStep
                      v-on:nextStep="nextStep"
                      :disabled="moneda == null"
                      :message="msgBtnSig"
                    />
                  </div>
                </v-stepper-content>

                <v-stepper-content step="4">
                  <template>
                    <v-container>
                      <v-row no-gutters>
                        <v-col cols="12" sm="6" md="7">
                          <v-card class="pa-10" outlined tile>
                            <div class="pl-3">
                              <p>
                                <v-icon>
                                  mdi-credit-card
                                </v-icon>
                                {{ metodoPagoLabel }}
                              </p>

                              <v-radio-group v-model="formaPago" row>
                                <v-radio
                                  :label="financiamientoLabel"
                                  value="financiamiento"
                                  color="#042956"
                                ></v-radio>

                                <v-radio
                                  :label="contadoLabel"
                                  value="contado"
                                  color="#042956"
                                ></v-radio>
                              </v-radio-group>

                              <div>
                                <v-row>
                                  <v-col cols="4">
                                    <v-select
                                      :items="porcentajesEnganche"
                                      :label="engancheLabel"
                                      v-model="porcentajeEnganche"
                                      color="#042956"
                                    ></v-select>
                                  </v-col>
                                  <v-col cols="4">
                                    <v-text-field
                                      :label="reservaLabel"
                                      v-model="cantidadReserva"
                                      prefix="$"
                                      type="number"
                                      step="0.01"
                                      color="#042956"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="4">
                                    <div v-show="formaPago == 'financiamiento'">
                                      <!-- <v-select
                                      :items="mesesFinanciamiento"
                                      :label="mesesLabel"
                                      v-model="mesesFinanciamientoSeleccionados"
                                      color="#042956"
                                    ></v-select> -->
                                      <v-text-field
                                        :label="mesesLabel"
                                        v-model="
                                          mesesFinanciamientoSeleccionados
                                        "
                                        prefix="#"
                                        type="number"
                                        step="0.01"
                                        color="#042956"
                                      ></v-text-field>
                                    </div>
                                  </v-col>
                                </v-row>
                              </div>
                              <v-icon>
                                mdi-sale
                              </v-icon>
                              {{ tipoDescuentoLabel }}
                              <v-radio-group v-model="tipoDescuento" row>
                                <v-radio
                                  :label="porcentajeLabel"
                                  value="porcentaje"
                                  color="#042956"
                                ></v-radio>

                                <v-radio
                                  :label="fijoLabel"
                                  value="fijo"
                                  color="#042956"
                                ></v-radio>
                              </v-radio-group>
                              <v-text-field
                                :label="
                                  tipoDescuento == 'porcentaje'
                                    ? descuentoLabel + ' %'
                                    : descuentoLabel + ' $'
                                "
                                v-model="cantidadDescuento"
                                type="number"
                                step="0.01"
                                color="#042956"
                              ></v-text-field>

                              <!-- {{ includeIvaLabel }}
            <v-radio-group v-model="includeIva" row>
              <v-radio :label="ivaLabelOn" value="on" color="#042956"></v-radio>
              <v-radio
                :label="ivaLabelOff"
                value="off"
                color="#042956"
              ></v-radio>
            </v-radio-group>
            <div v-show="includeIva === 'on'">
              <v-text-field
                v-model="iva"
                prefix="%"
                type="number"
                step="0.01"
                color="#042956"
              ></v-text-field>
            </div> -->
                            </div>
                          </v-card>
                        </v-col>
                        <v-col cols="6" md="5">
                          <br />
                          <div v-if="listaPropiedadesSeleccionadas.length == 0">
                            {{ noHayNada }}
                          </div>
                          <div v-else>
                            <div v-show="formaPago == 'financiamiento'">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left"></th>
                                      <th class="text-left">
                                        {{ porcentajeLabel }}
                                      </th>
                                      <th class="text-left">
                                        {{ moneda }}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-show="cantidadReserva > 0">
                                      <td>{{ reservaLabel }}</td>
                                      <td>
                                        {{
                                          porcentajeFormatter.format(
                                            porcentajeReserva
                                          )
                                        }}
                                      </td>
                                      <td>
                                        {{
                                          moneda +
                                            " " +
                                            monedaFormatter.format(
                                              cantidadReserva
                                            )
                                        }}
                                      </td>
                                    </tr>
                                    <!-- Si hay enganche -->
                                    <tr v-show="porcentajeEnganche">
                                      <td>{{ engancheLabel }}</td>
                                      <td>
                                        {{
                                          porcentajeFormatter.format(
                                            porcentajeEnganche -
                                              porcentajeReserva
                                          )
                                        }}
                                      </td>
                                      <td>
                                        {{
                                          moneda +
                                            " " +
                                            monedaFormatter.format(
                                              precioEnganche
                                            )
                                        }}
                                      </td>
                                    </tr>
                                    <tr v-show="porcentajeEnganche">
                                      <td>{{ financiamientoLabel }}</td>
                                      <td>
                                        {{
                                          porcentajeFormatter.format(
                                            porcentajeFinanciamiento
                                          )
                                        }}
                                      </td>
                                      <td>
                                        {{
                                          moneda +
                                            " " +
                                            monedaFormatter.format(
                                              cantidadFinanciamiento
                                            )
                                        }}
                                      </td>
                                    </tr>
                                    <tr v-show="porcentajeEnganche">
                                      <td>
                                        <span class="font-weight-bold">
                                          {{ mesesFinanciamientoSeleccionados }}
                                        </span>
                                        {{ " " + mesesLabel }}
                                      </td>
                                      <td></td>
                                      <td>
                                        {{
                                          moneda +
                                            " " +
                                            monedaFormatter.format(
                                              isFinite(
                                                cantidadFinanciamiento /
                                                  mesesFinanciamientoSeleccionados
                                              )
                                                ? cantidadFinanciamiento /
                                                    mesesFinanciamientoSeleccionados
                                                : 0
                                            )
                                        }}
                                      </td>
                                    </tr>

                                    <!-- Si no hay enganche -->
                                    <tr v-show="!porcentajeEnganche">
                                      <td>{{ financiamientoLabel }}</td>
                                      <td>
                                        {{
                                          porcentajeFormatter.format(
                                            1 - porcentajeReserva
                                          )
                                        }}
                                      </td>
                                      <td>
                                        {{
                                          moneda +
                                            " " +
                                            monedaFormatter.format(
                                              precioTotal - cantidadReserva
                                            )
                                        }}
                                      </td>
                                    </tr>
                                    <tr v-show="!porcentajeEnganche">
                                      <td>
                                        <span class="font-weight-bold">
                                          {{ mesesFinanciamientoSeleccionados }}
                                        </span>
                                        {{ " " + mesesLabel }}
                                      </td>
                                      <td></td>
                                      <td>
                                        {{
                                          moneda +
                                            " " +
                                            monedaFormatter.format(
                                              (precioTotal - cantidadReserva) /
                                                mesesFinanciamientoSeleccionados
                                            )
                                        }}
                                      </td>
                                    </tr>

                                    <tr v-show="includeIva === 'on'">
                                      <td>{{ ivaLabel }}</td>
                                      <td>
                                        {{ `${iva}%` }}
                                      </td>
                                      <td>
                                        {{
                                          moneda +
                                            " " +
                                            monedaFormatter.format(
                                              porcentajeIVA
                                            )
                                        }}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        Total
                                        <span
                                          v-if="cantidadDescuento > 0"
                                          class="font-weight-bold"
                                        >
                                          {{ menosDescuentoLabel }}
                                        </span>
                                      </td>
                                      <td>100%</td>
                                      <td>
                                        {{
                                          moneda +
                                            " " +
                                            monedaFormatter.format(precioTotal)
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </div>

                            <div v-show="formaPago == 'contado'">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left"></th>
                                      <th class="text-left">
                                        {{ porcentajeLabel }}
                                      </th>
                                      <th class="text-left">
                                        {{ moneda }}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-show="cantidadReserva > 0">
                                      <td>{{ reservaLabel }}</td>
                                      <td>
                                        {{
                                          porcentajeFormatter.format(
                                            porcentajeReserva
                                          )
                                        }}
                                      </td>
                                      <td>
                                        {{
                                          moneda +
                                            " " +
                                            monedaFormatter.format(
                                              cantidadReserva
                                            )
                                        }}
                                      </td>
                                    </tr>

                                    <!-- Si hay enganche se muestra -->
                                    <tr v-show="porcentajeEnganche">
                                      <td>{{ engancheLabel }}</td>
                                      <td>
                                        {{
                                          porcentajeFormatter.format(
                                            porcentajeEnganche -
                                              porcentajeReserva
                                          )
                                        }}
                                      </td>
                                      <td>
                                        {{
                                          moneda +
                                            " " +
                                            monedaFormatter.format(
                                              precioEnganche
                                            )
                                        }}
                                      </td>
                                    </tr>

                                    <!-- Si no hay enganche se muestra -->
                                    <tr v-show="!porcentajeEnganche">
                                      <td>{{ contadoLabel }}</td>
                                      <td>
                                        {{
                                          porcentajeFormatter.format(
                                            1 - porcentajeReserva
                                          )
                                        }}
                                      </td>
                                      <td>
                                        {{
                                          moneda +
                                            " " +
                                            monedaFormatter.format(
                                              precioEnganche
                                            )
                                        }}
                                      </td>
                                    </tr>

                                    <!-- Si hay enganche se muestra -->
                                    <tr v-show="porcentajeEnganche">
                                      <td>{{ contadoLabel }}</td>
                                      <td>
                                        {{
                                          porcentajeFormatter.format(
                                            porcentajeFinanciamiento
                                          )
                                        }}
                                      </td>
                                      <td>
                                        {{
                                          moneda +
                                            " " +
                                            monedaFormatter.format(
                                              cantidadFinanciamiento
                                            )
                                        }}
                                      </td>
                                    </tr>

                                    <tr v-show="includeIva === 'on'">
                                      <td>{{ ivaLabel }}</td>
                                      <td>
                                        {{ `${iva}%` }}
                                      </td>
                                      <td>
                                        {{
                                          moneda +
                                            " " +
                                            monedaFormatter.format(
                                              porcentajeIVA
                                            )
                                        }}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        Total
                                        <span
                                          v-if="cantidadDescuento > 0"
                                          class="font-weight-bold"
                                        >
                                          {{ menosDescuentoLabel }}
                                        </span>
                                      </td>
                                      <td>100%</td>
                                      <td>
                                        {{
                                          moneda +
                                            " " +
                                            monedaFormatter.format(precioTotal)
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>

                  <div class="d-flex justify-space-between">
                    <btnPreviousStep
                      v-on:previousStep="previousStep"
                      :message="msgBtnPrev"
                    />
                    <btnNextStep
                      v-on:nextStep="nextStep"
                      :disabled="formaPago == null"
                      :message="msgBtnSig"
                    />
                  </div>
                </v-stepper-content>

                <v-stepper-content step="5">
                  <div v-if="listaPropiedadesSeleccionadas.length == 0">
                    {{ noHayNada }}
                  </div>
                  <div v-else>
                    <div v-show="formaPago == 'financiamiento'">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left"></th>
                              <th class="text-left">
                                {{ porcentajeLabel }}
                              </th>
                              <th class="text-left">
                                {{ moneda }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-show="cantidadReserva > 0">
                              <td>{{ reservaLabel }}</td>
                              <td>
                                {{
                                  porcentajeFormatter.format(porcentajeReserva)
                                }}
                              </td>
                              <td>
                                {{
                                  moneda +
                                    " " +
                                    monedaFormatter.format(cantidadReserva)
                                }}
                              </td>
                            </tr>
                            <!-- Si hay enganche -->
                            <tr v-show="porcentajeEnganche">
                              <td>{{ engancheLabel }}</td>
                              <td>
                                {{
                                  porcentajeFormatter.format(
                                    porcentajeEnganche - porcentajeReserva
                                  )
                                }}
                              </td>
                              <td>
                                {{
                                  moneda +
                                    " " +
                                    monedaFormatter.format(precioEnganche)
                                }}
                              </td>
                            </tr>
                            <tr v-show="porcentajeEnganche">
                              <td>{{ financiamientoLabel }}</td>
                              <td>
                                {{
                                  porcentajeFormatter.format(
                                    porcentajeFinanciamiento
                                  )
                                }}
                              </td>
                              <td>
                                {{
                                  moneda +
                                    " " +
                                    monedaFormatter.format(
                                      cantidadFinanciamiento
                                    )
                                }}
                              </td>
                            </tr>
                            <tr v-show="porcentajeEnganche">
                              <td>
                                <span class="font-weight-bold">
                                  {{ mesesFinanciamientoSeleccionados }}
                                </span>
                                {{ " " + mesesLabel }}
                              </td>
                              <td></td>
                              <td>
                                {{
                                  moneda +
                                    " " +
                                    monedaFormatter.format(
                                      isFinite(
                                        cantidadFinanciamiento /
                                          mesesFinanciamientoSeleccionados
                                      )
                                        ? cantidadFinanciamiento /
                                            mesesFinanciamientoSeleccionados
                                        : 0
                                    )
                                }}
                              </td>
                            </tr>

                            <!-- Si no hay enganche -->
                            <tr v-show="!porcentajeEnganche">
                              <td>{{ financiamientoLabel }}</td>
                              <td>
                                {{
                                  porcentajeFormatter.format(
                                    1 - porcentajeReserva
                                  )
                                }}
                              </td>
                              <td>
                                {{
                                  moneda +
                                    " " +
                                    monedaFormatter.format(
                                      precioTotal - cantidadReserva
                                    )
                                }}
                              </td>
                            </tr>
                            <tr v-show="!porcentajeEnganche">
                              <td>
                                <span class="font-weight-bold">
                                  {{ mesesFinanciamientoSeleccionados }}
                                </span>
                                {{ " " + mesesLabel }}
                              </td>
                              <td></td>
                              <td>
                                {{
                                  moneda +
                                    " " +
                                    monedaFormatter.format(
                                      (precioTotal - cantidadReserva) /
                                        mesesFinanciamientoSeleccionados
                                    )
                                }}
                              </td>
                            </tr>

                            <tr v-show="includeIva === 'on'">
                              <td>{{ ivaLabel }}</td>
                              <td>
                                {{ `${iva}%` }}
                              </td>
                              <td>
                                {{
                                  moneda +
                                    " " +
                                    monedaFormatter.format(porcentajeIVA)
                                }}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                Total
                                <span
                                  v-if="cantidadDescuento > 0"
                                  class="font-weight-bold"
                                >
                                  {{ menosDescuentoLabel }}
                                </span>
                              </td>
                              <td>100%</td>
                              <td>
                                {{
                                  moneda +
                                    " " +
                                    monedaFormatter.format(precioTotal)
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </div>

                    <div v-show="formaPago == 'contado'">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left"></th>
                              <th class="text-left">
                                {{ porcentajeLabel }}
                              </th>
                              <th class="text-left">
                                {{ moneda }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-show="cantidadReserva > 0">
                              <td>{{ reservaLabel }}</td>
                              <td>
                                {{
                                  porcentajeFormatter.format(porcentajeReserva)
                                }}
                              </td>
                              <td>
                                {{
                                  moneda +
                                    " " +
                                    monedaFormatter.format(cantidadReserva)
                                }}
                              </td>
                            </tr>

                            <!-- Si hay enganche se muestra -->
                            <tr v-show="porcentajeEnganche">
                              <td>{{ engancheLabel }}</td>
                              <td>
                                {{
                                  porcentajeFormatter.format(
                                    porcentajeEnganche - porcentajeReserva
                                  )
                                }}
                              </td>
                              <td>
                                {{
                                  moneda +
                                    " " +
                                    monedaFormatter.format(precioEnganche)
                                }}
                              </td>
                            </tr>

                            <!-- Si no hay enganche se muestra -->
                            <tr v-show="!porcentajeEnganche">
                              <td>{{ contadoLabel }}</td>
                              <td>
                                {{
                                  porcentajeFormatter.format(
                                    1 - porcentajeReserva
                                  )
                                }}
                              </td>
                              <td>
                                {{
                                  moneda +
                                    " " +
                                    monedaFormatter.format(precioEnganche)
                                }}
                              </td>
                            </tr>

                            <!-- Si hay enganche se muestra -->
                            <tr v-show="porcentajeEnganche">
                              <td>{{ contadoLabel }}</td>
                              <td>
                                {{
                                  porcentajeFormatter.format(
                                    porcentajeFinanciamiento
                                  )
                                }}
                              </td>
                              <td>
                                {{
                                  moneda +
                                    " " +
                                    monedaFormatter.format(
                                      cantidadFinanciamiento
                                    )
                                }}
                              </td>
                            </tr>

                            <tr v-show="includeIva === 'on'">
                              <td>{{ ivaLabel }}</td>
                              <td>
                                {{ `${iva}%` }}
                              </td>
                              <td>
                                {{
                                  moneda +
                                    " " +
                                    monedaFormatter.format(porcentajeIVA)
                                }}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                Total
                                <span
                                  v-if="cantidadDescuento > 0"
                                  class="font-weight-bold"
                                >
                                  {{ menosDescuentoLabel }}
                                </span>
                              </td>
                              <td>100%</td>
                              <td>
                                {{
                                  moneda +
                                    " " +
                                    monedaFormatter.format(precioTotal)
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </div>
                  </div>

                  <div class="pa-4 d-flex justify-space-between">
                    <btnPreviousStep
                      v-on:previousStep="previousStep"
                      :message="msgBtnPrev"
                    />
                    <v-spacer></v-spacer>
                    <btnNextStep
                      v-on:nextStep="nextStep"
                      :disabled="false"
                      :message="msgBtnSig"
                    />
                  </div>
                </v-stepper-content>
                <v-stepper-content step="6">
                  <v-row align="center">
                    <v-col cols="6">
                      <v-subheader>
                        <div v-if="selectedLanguage == 'Español'">
                          Selecciona un Broker
                        </div>
                        <div v-else>
                          Select Broker
                        </div>
                      </v-subheader>
                    </v-col>

                    <v-col cols="6">
                      <!-- <v-select
                v-model="contactSelected"
                :items="contactsList"
                item-text="name"
                item-value="abbr"
                label="Broker"
                persistent-hint
                return-object
                single-line
              ></v-select> -->
                      <select-contact-broker
                        @getOption="getContactBrokerOption"
                        :page="'realEstate'"
                        :lead="null"
                      />
                    </v-col>
                  </v-row>

                  <div class="pa-4 d-flex justify-space-between">
                    <btnPreviousStep
                      v-on:previousStep="previousStep"
                      :message="msgBtnPrev"
                    />
                    <v-btn @click="newQuote" style=" margin-left: 20px;">
                      <v-icon center>
                        mdi-reload
                      </v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn
                      v-on:click="startLoading"
                      :disabled="contactSelected == null || creatingPdf"
                      :loading="creatingPdf"
                      color="#042956"
                      class="white--text"
                    >
                      PDF
                      <v-icon right>
                        mdi-arrow-right
                      </v-icon>
                    </v-btn>
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <p>
                Esta opción actualmente se está desarrollando.
              </p>
            </v-card-text>
            <PromisePDF />
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-container>
  </div>
</template>

<script>
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import printPDFQuote from "@/pdf/qoute-print-pdf.js";
import { GetRealEstateDevelopmentDetail } from "@/api/real-estate-development-api.js";
import { GetProperties } from "@/api/property-api.js";
// import {
//   ListRulesPricing
// } from "@/api/real-estate-pricing-rules-api.js";
export default {
  name: "Quote",
  components: {
    BtnNextStep: () =>
      import("@/components/real-estate-development/quote/BtnNextStep.vue"),
    BtnPreviousStep: () =>
      import("@/components/real-estate-development/quote/BtnPreviousStep.vue"),
    SelectContactBroker: () =>
      import("@/components/contact-broker/SelectContactBroker.vue"),
    PromisePDF: () =>
      import("@/components/real-estate-development/quote/PromisePDF.vue")
  },
  data: () => ({
    styles_pdf_to_null: {
      container_one: "#262D3C",
      container_two: "#FFFFFF",
      //container_separator: "#000000",
      text_color: "#FFFFFF",
      title_color: "#262D3C",
      title2_color: "#262D3C",
      text3_color: "#262D3C",
      featured_image: null,
      logo: null,
      realEstateGroupLogo: null,
      //Se agrega la opcion de poder ocultar la divisa
      hideCurrency: false
    },
    errorMsg: "",
    creatingPdf: false,
    showAvailables: false,
    contactSelected: null,
    contactsList: null,
    listaPropiedadesInfo: null,
    loading: true,
    loadingStatus: "Loading properties ...",
    errored: false,
    listaPropiedadesIDs: [],
    listaPropiedadesSeleccionadas: [],
    overlay: true,
    stepNumber: 1,
    languagesList: ["English", "Español"],
    selectedLanguage: "Español",
    moneda: "",
    defMoneda: "",
    tipoCambio: 20,
    formaPago: "financiamiento",
    porcentajesEnganche: [
      { text: "Sin enganche", value: 0 }
      /* { text: "10%", value: 0.1 },
      { text: "15%", value: 0.15 },
      { text: "20%", value: 0.2 },
      { text: "25%", value: 0.25 },
      { text: "30%", value: 0.3 },
      { text: "35%", value: 0.35 },
      { text: "40%", value: 0.4 },
      { text: "45%", value: 0.45 },
      { text: "50%", value: 0.5 },
      { text: "55%", value: 0.55 },
      { text: "60%", value: 0.6 },
      { text: "65%", value: 0.65 },
      { text: "70%", value: 0.7 },
      { text: "75%", value: 0.75 },
      { text: "80%", value: 0.8 },
      { text: "85%", value: 0.85 },
      { text: "90%", value: 0.9 } */
    ],
    porcentajeEnganche: 0.5,
    mesesFinanciamientoSeleccionados: 0,
    mesesFinanciamiento: [6, 12, 18, 24, 30, 36],
    cantidadReserva: 0,
    tipoDescuento: "porcentaje",
    cantidadDescuento: 0,
    nombreProspecto: "",
    monedaFormatter: new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0
    }),
    porcentajeFormatter: new Intl.NumberFormat("en-US", {
      style: "percent",
      maximumFractionDigits: 2
    }),
    contactDataDevelopment: null,
    addressDataDevelopment: null,
    mediaDataDevelopment: null,
    stylePdfDataDevelopment: null,
    includeIva: "off",
    iva: 0,
    totalIVA: 0
  }),
  methods: {
    consultDetails() {
      let self = this;
      setTimeout(() => {
        GetRealEstateDevelopmentDetail(this.realEstateCode)
          .then(function(res) {
            const develoment = res.RealEstateDevelopment;
            if (develoment.contact_data && "email" in develoment.contact_data) {
              self.contactDataDevelopment = develoment.contact_data;
            }

            if (develoment.address) {
              self.addressDataDevelopment = develoment.address;
            } else {
              self.addressDataDevelopment = {
                state: "",
                city: "",
                full_address: ""
              };
            }
            if (develoment.media) {
              self.mediaDataDevelopment = develoment.media;
            }
            if (develoment.miscellaneous && develoment.miscellaneous.quote) {
              if (develoment.miscellaneous.quote.styles_pdf) {
                self.stylePdfDataDevelopment =
                  develoment.miscellaneous.quote.styles_pdf;
              }
              if (
                develoment.miscellaneous.quote.vat &&
                develoment.miscellaneous.quote.vat > 0
              ) {
                self.includeIva = "on";
                self.iva = develoment.miscellaneous.quote.vat;
              }
            }
          })
          .catch(error => error)
          .finally();
      }, 1500);
    }, //api
    consultProperties() {
      let self = this;
      GetProperties(this.realEstateCode)
        .then(res => {
          self.listaPropiedadesInfo = res.Property;
          res.Property.forEach(propiedad => {
            if (propiedad.contract_status === "disponible") {
              // self.listaPropiedadesIDs.push(propiedad.code);
              const property = {
                name: propiedad.code,
                code: propiedad.code,
                building: propiedad.building,
                media: propiedad.media,
                pricing: propiedad.pricing,
                floor: propiedad.floor,
                type: propiedad.type,
                features: propiedad.features,
                amenities: propiedad.amenities
              };
              self.listaPropiedadesIDs.push(property);

              if (this.$route.params.property) {
                if (this.$route.params.property === propiedad._id) {
                  this.listaPropiedadesSeleccionadas.push(property);
                  this.selectedCurrency(this.listaPropiedadesSeleccionadas);
                }
              }
            }
          });
        })
        .catch(error => error)
        .finally();
    },
    // consultPricingRules() {
    // //   let self = this;
    //   setTimeout(() => {
    //     ListRulesPricing(this.realEstateID)
    //       .then(res => {
    //         if (res || res.length > 0) {
    //         }
    //       })
    //       .catch(error => error)
    //       .finally();
    //   }, 1500);
    // }
    nextStep() {
      this.stepNumber++;
    },
    previousStep() {
      this.stepNumber--;
    },
    getContactBrokerOption(option) {
      if (option) {
        this.contactSelected = option;
      }
    },
    newQuote() {
      setTimeout(function() {
        window.location.reload();
      }, 1000);
    },
    startLoading() {
      let self = this;
      this.creatingPdf = true;
      setTimeout(function() {
        self.createPdf().then(() => {
          self.creatingPdf = false;
          //window.location.reload();
        });
      }, 1000);
    },
    async createPdf() {
      let dts = [];

      if (this.cantidadReserva > 0) {
        dts.push([
          this.reservaLabel,
          this.porcentajeFormatter.format(this.porcentajeReserva),
          this.monedaFormatter.format(this.cantidadReserva)
        ]);
      }

      if (this.formaPago == "financiamiento") {
        if (this.porcentajeEnganche) {
          dts.push([
            this.engancheLabel,
            this.porcentajeFormatter.format(
              this.porcentajeEnganche - this.porcentajeReserva
            ),
            this.monedaFormatter.format(this.precioEnganche)
          ]);
          dts.push([
            this.financiamientoLabel,
            this.porcentajeFormatter.format(this.porcentajeFinanciamiento),

            this.monedaFormatter.format(this.cantidadFinanciamiento)
          ]);
          dts.push([
            this.mesesFinanciamientoSeleccionados + " " + this.mesesLabel,
            "",

            this.monedaFormatter.format(
              isFinite(
                this.cantidadFinanciamiento /
                  this.mesesFinanciamientoSeleccionados
              )
                ? this.cantidadFinanciamiento /
                    this.mesesFinanciamientoSeleccionados
                : 0
            )
          ]);
        }

        if (!this.porcentajeEnganche) {
          dts.push([
            this.financiamientoLabel,
            this.porcentajeFormatter.format(1 - this.porcentajeReserva),

            this.monedaFormatter.format(this.precioTotal - this.cantidadReserva)
          ]);
          dts.push([
            this.mesesFinanciamientoSeleccionados + " " + this.mesesLabel,
            "",

            this.monedaFormatter.format(
              (this.precioTotal - this.cantidadReserva) /
                this.mesesFinanciamientoSeleccionados
            )
          ]);
        }
        if (this.includeIva === "on") {
          dts.push([
            this.ivaLabel,
            `${this.iva}%`,
            this.monedaFormatter.format(this.totalIVA)
          ]);
        }
        dts.push([
          this.cantidadDescuento > 0
            ? "Total " + this.menosDescuentoLabel
            : "Total ",
          "100 %",
          this.monedaFormatter.format(this.precioTotal)
        ]);
      }

      if (this.formaPago == "contado") {
        if (this.porcentajeEnganche) {
          //dts.push([, ,]);

          dts.push([
            this.engancheLabel,
            this.porcentajeFormatter.format(
              this.porcentajeEnganche - this.porcentajeReserva
            ),
            this.monedaFormatter.format(this.precioEnganche)
          ]);
          dts.push([
            this.contadoLabel,
            this.porcentajeFormatter.format(this.porcentajeFinanciamiento),

            this.monedaFormatter.format(this.cantidadFinanciamiento)
          ]);
        }

        if (!this.porcentajeEnganche) {
          dts.push([
            this.contadoLabel,
            this.porcentajeFormatter.format(1 - this.porcentajeReserva),
            this.monedaFormatter.format(this.precioEnganche)
          ]);
        }

        if (this.includeIva === "on") {
          dts.push([
            this.ivaLabel,
            `${this.iva}%`,
            this.monedaFormatter.format(this.totalIVA)
          ]);
        }

        dts.push([
          this.cantidadDescuento > 0
            ? "Total " + this.menosDescuentoLabel
            : "Total ",
          "100 %",
          this.monedaFormatter.format(this.precioTotal)
        ]);
      }

      let medias;
      if (this.stylePdfDataDevelopment) {
        medias = {
          logo: {
            src: this.stylePdfDataDevelopment.logo
              ? this.stylePdfDataDevelopment.logo
              : null
          },
          featured_image: {
            src: this.stylePdfDataDevelopment.featured_image
              ? this.stylePdfDataDevelopment.featured_image
              : null
          },
          logo_footer: {
            src: this.stylePdfDataDevelopment.logo_footer
              ? this.stylePdfDataDevelopment.logo_footer
              : null
          }
        };
      } else {
        this.stylePdfDataDevelopment = this.styles_pdf_to_null;
      }

      if (this.nombreProspecto && this.precioTotal > 0) {
        try {
          printPDFQuote.portada(
            this.nombreProspecto,
            this.contactSelected.name,
            this.selectedLanguage,
            this.contactSelected.media,
            this.contactSelected,
            this.addressDataDevelopment,
            medias,
            this.stylePdfDataDevelopment
          );
          const doc = printPDFQuote.departamentos(
            this.listaPropiedadesSeleccionadas,
            this.contactSelected.name,
            this.selectedLanguage,
            dts,
            this.moneda,
            this.tipoCambio,
            this.defMoneda != this.moneda,
            this.includeIva,
            this.totalIVA,
            this.stylePdfDataDevelopment.realEstateGroupLogo,
            this.stylePdfDataDevelopment.hideCurrency
              ? this.stylePdfDataDevelopment.hideCurrency
              : null
          );
          doc.save(this.nombreProspecto + ".pdf");
        } catch (error) {
          alert(
            "Error al generar el pdf (Comprueba la configuración del cortizador)"
          );
          window.location.reload();
        }
      } else {
        alert(
          this.selectedLanguage == "Español"
            ? "Complete todos los campos por favor"
            : "Complete all fields please"
        );
      }
    },
    selectedCurrency(optionProperties) {
      const self = this;
      optionProperties.forEach(properti => {
        self.moneda = properti.pricing.currency;
        self.defMoneda = properti.pricing.currency;
      });
    },
    getPorcentajeIVA() {
      if (this.includeIva === "on") {
        return (this.totalIVA = (this.iva / 100) * this.precioTotal);
      } else return (this.totalIVA = 0);
    },
    fillHitch() {
      for (var i = 1; i < 100; i++) {
        this.porcentajesEnganche.push({
          text: i + "%",
          value: (i * 0.01).toFixed(2)
        });
      }
    },
    textSelected(item) {
      return item.code + " - " + item.floor.name;
    }
  },
  created() {
    this.realEstateCode = this.$route.params.code;
    this.consultDetails();
    this.consultProperties();
    // this.consultPricingRules();
    this.$emit(`update:layout`, DefaultLayout);
    this.fillHitch();
  },
  computed: {
    paso1TextoCabezera() {
      return this.selectedLanguage == "Español"
        ? "Ingresa nombre del Lead"
        : "Enter the prospect's name";
    },
    paso2TextoCabezera() {
      return this.selectedLanguage == "Español"
        ? "Selecciona las propiedades"
        : "select the properties";
    },
    paso3TextoCabezera() {
      return this.selectedLanguage == "Español"
        ? "Elegir moneda"
        : "Choose currency";
    },
    paso4TextoCabezera() {
      return this.selectedLanguage == "Español"
        ? "Forma de pago"
        : "Payment method";
    },
    paso5TextoCabezera() {
      return this.selectedLanguage == "Español"
        ? "Esquema de pagos"
        : "Payment scheme";
    },
    msgBtnSig() {
      return this.selectedLanguage == "Español" ? "Siguiente" : "Next";
    },
    msgBtnPrev() {
      return this.selectedLanguage == "Español" ? "Atras" : "Previous";
    },
    nombreProspectoLabel() {
      return this.selectedLanguage == "Español"
        ? "Nombre del Lead"
        : "Prospect's name";
    },
    propiedadesLabel() {
      return this.selectedLanguage == "Español" ? "Propiedades" : "Properties";
    },
    elijeMoneda() {
      return this.selectedLanguage == "Español"
        ? "Elije la moneda"
        : "Choose the currency";
    },
    tipoCambioLabel() {
      return this.selectedLanguage == "Español"
        ? "Tipo de cambio"
        : "Exchange rate";
    },
    metodoPagoLabel() {
      return this.selectedLanguage == "Español"
        ? "Elije tu forma de pago"
        : "Choose your payment method";
    },
    financiamientoLabel() {
      return this.selectedLanguage == "Español"
        ? "Financiamiento"
        : "Financing";
    },
    contadoLabel() {
      return this.selectedLanguage == "Español" ? "Contado" : "Cash";
    },
    engancheLabel() {
      return this.selectedLanguage == "Español" ? "Enganche" : "DownPayment";
    },
    reservaLabel() {
      return this.selectedLanguage == "Español" ? "Reserva" : "Reserve";
    },
    mesesLabel() {
      return this.selectedLanguage == "Español"
        ? "Pagos mensuales"
        : "Monthly payments";
    },
    tipoDescuentoLabel() {
      return this.selectedLanguage == "Español"
        ? "Elije el tipo de descuento"
        : "Choose the discount type";
    },
    includeIvaLabel() {
      return this.selectedLanguage == "Español"
        ? "¿Precios incluyen IVA?"
        : "Prices include VAT?";
    },
    ivaLabelOn() {
      return this.selectedLanguage == "Español" ? "SI" : "Yes";
    },
    ivaLabelOff() {
      return this.selectedLanguage == "Español" ? "No" : "Not";
    },
    ivaLabel() {
      return this.selectedLanguage == "Español" ? "IVA" : "VAT";
    },
    porcentajeLabel() {
      return this.selectedLanguage == "Español" ? "Porcentaje" : "Percentage";
    },
    fijoLabel() {
      return this.selectedLanguage == "Español" ? "Fijo" : "Fixed";
    },
    descuentoLabel() {
      return this.selectedLanguage == "Español" ? "Descuento" : "Discount";
    },
    noHayNada() {
      return this.selectedLanguage == "Español"
        ? "Favor de seleccionar las propiedades"
        : "Please select your properties";
    },
    precioTotal() {
      let precioTotalSum = 0;
      const slmoneda = this.moneda;
      for (const propId of this.listaPropiedadesSeleccionadas) {
        precioTotalSum += propId.pricing.price;
      }
      if (this.defMoneda != slmoneda) {
        if (slmoneda === "MXN") {
          precioTotalSum = precioTotalSum * this.tipoCambio;
        }
        if (slmoneda === "USD") {
          precioTotalSum = precioTotalSum / this.tipoCambio;
        }
      }

      if (this.includeIva === "on") {
        precioTotalSum = precioTotalSum + this.totalIVA;
      }

      if (this.tipoDescuento == "porcentaje") {
        return precioTotalSum - (precioTotalSum * this.cantidadDescuento) / 100;
      } else {
        return precioTotalSum - this.cantidadDescuento;
      }
    },
    porcentajeFinanciamiento() {
      return 1 - this.porcentajeEnganche;
    },
    cantidadFinanciamiento() {
      return this.precioTotal * this.porcentajeFinanciamiento;
    },
    porcentajeReserva() {
      return this.cantidadReserva / this.precioTotal;
    },
    porcentajeIVA() {
      return this.getPorcentajeIVA();
    },
    precioEnganche() {
      if (this.porcentajeEnganche != 0) {
        return (
          this.porcentajeEnganche * this.precioTotal - this.cantidadReserva
        );
      } else {
        return this.precioTotal - this.cantidadReserva;
      }
    },
    menosDescuentoLabel() {
      if (this.selectedLanguage == "Español") {
        return this.tipoDescuento == "porcentaje"
          ? `(Con ${this.cantidadDescuento}% de descuento)`
          : `(Con ${this.moneda +
              " " +
              this.monedaFormatter.format(
                this.cantidadDescuento
              )} de descuento)`;
      } else {
        return this.tipoDescuento == "porcentaje"
          ? `(Minus ${this.cantidadDescuento}% discount)`
          : `(Minus ${this.moneda +
              " " +
              this.monedaFormatter.format(this.cantidadDescuento)} discount)`;
      }
    }
  }
};
</script>
<style scoped>
.v-stepper__label {
  width: 50% !important;
}
.v-stepper__step {
  padding: 20px !important;
}
.v-stepper__wrapper > div > .v-text-field {
  padding-top: 10px !important;
}
</style>
